import React, {useState, useEffect, useRef} from "react"
import {Button} from "./ui"
import Img from "gatsby-image"
import {ArrowRight} from "react-feather"

import Parallax from "../utils/parallax"
import {IndexPageQuery_portfolio_edges_node} from "../pages/__generated__/IndexPageQuery"

type ItemPortfolioProps = { data: IndexPageQuery_portfolio_edges_node, even: boolean };
export const ItemPortfolio: React.FC<ItemPortfolioProps> = ({data, even}) => {

    return (

        <div className="large-container mx-auto">
            <div className={`my-4 py-8 lg:py-24 portfolio-item md:flex ${even ? "even flex-row-reverse" : ""}`}>
                <div className="relative flex-1">
                    <div className="image relative">
                        <Img
                            fluid={
                                data.frontmatter.image.childImageSharp.fluid
                            }
                            alt={data.frontmatter.title}
                            className="rounded"
                        />
                    </div>
                </div>
                <div className="flex-1 flex mt-2 md:px-4 lg:px-6 items-center">
                    <div
                        className={`flex flex-1 flex-wrap  ${
                            even ? "md:justify-end md:text-right" : ""
                        }`}
                    >
                        <p className="lg:mt-4 text-xl md:text-2xl to-up font-bold">
                            {data.frontmatter.description}
                        </p>
                        <h3 className="text-color-1 text-xl font-black to-up">
                            {data.frontmatter.title}
                        </h3>
                        {/*<Button*/}
                        {/*    to={data.fields.slug}*/}
                        {/*    label={`View ${data.frontmatter.title}`}*/}
                        {/*    title={"View"}*/}
                        {/*    iconRight={<ArrowRight />}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemPortfolio;
